import React, { useState } from "react";
import CommonServices from "../../../shared/services/commonService";
import SnackbarComponent from "../../atoms/snackbar";
import { useEffect } from "react";
import { Isnackbar } from "../../../shared/interfaces/snackbar";

const BackgroundEffects = (props: any) => {
  let mainWindow: any;

  if (typeof window !== "undefined") {
    // Client-side-only code
    mainWindow = window;
  } else {
    mainWindow = null;
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarDuration, setSnackbarDuration] = useState(0);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [actionOpen, setActionOpen] = useState("");
  const [action, setAction] = useState<boolean | undefined>(false);
  const [snackbarPos1, setSnackbarPos1] = useState<
    "top" | "bottom" | undefined
  >("top");
  const [snackbarPos2, setSnackbarPos2] = useState<"left" | "center" | "right">(
    "center"
  );

  function closeSnackbar() {
    setSnackbarOpen(false);
  }

  CommonServices.getCommonServiceInstance().showToast$.subscribe(
    (item: Isnackbar) => {
      setSnackbarDuration(item.duration);
      setSnackbarSeverity(item.severity);
      setSnackbarMessage(item.message);
      setAction(item.action);
      if (item.pos1) {
        setSnackbarPos1(item.pos1);
      }
      if (item.pos2) {
        setSnackbarPos2(item.pos2);
      }

      if (item.actionOpen) {
        setActionOpen(item.actionOpen);
      }
      setSnackbarOpen(true);
    }
  );

  return (
    <>
      <div>
        <SnackbarComponent
          open={snackbarOpen}
          duration={snackbarDuration}
          severity={snackbarSeverity}
          message={snackbarMessage}
          closeSnackbar={closeSnackbar}
          pos1={snackbarPos1}
          pos2={snackbarPos2}
          action={action}
          actionOpen={actionOpen}
        ></SnackbarComponent>
        {/* </GoogleReCaptchaProvider> */}
      </div>
    </>
  );
};

export default BackgroundEffects;
