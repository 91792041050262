import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Fade, Box } from "@mui/material";
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windowAlt?: () => Window;
  children: React.ReactElement;
}
export function ScrollTop(props: Props) {
  let mainWindow: any;

  if (typeof window !== "undefined") {
    // Client-side-only code
    mainWindow = window;
  } else {
    mainWindow = null;
  }

  const { children, windowAlt } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: windowAlt ? windowAlt() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    mainWindow?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}
