import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { Isnackbar } from "../../interfaces/snackbar";
import Button from "@mui/material/Button";
import CommonServices from "../../shared/services/commonService";

export default function SnackbarComponent(props: Isnackbar) {
  function doToastAction(action: string) {
    if (action == "offers") {
      CommonServices.getCommonServiceInstance().openOffer$.next(true);
    }
  }

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.duration}
      onClose={props.closeSnackbar}
      anchorOrigin={{
        vertical: props.pos1 ?? "top",
        horizontal: props.pos2 ?? "center",
      }}
    >
      <Alert
        variant="filled"
        severity={props.severity}
        sx={{ width: "100%" }}
        action={
          props.action && (
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                doToastAction(props.actionOpen);
              }}
            >
              OPEN
            </Button>
          )
        }
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}
