import { BehaviorSubject, Subject } from "rxjs";
import { Isnackbar } from "../interfaces/snackbar";

export default class CommonServices {
  static commonServiceInstance: any = null;

  static getCommonServiceInstance() {
    if (this.commonServiceInstance == null) {
      this.commonServiceInstance = new CommonServices();
      return this.commonServiceInstance;
    } else {
      return this.commonServiceInstance;
    }
  }

  showToast$ = new Subject<Isnackbar>();
}
