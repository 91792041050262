import React, { lazy, useEffect, Suspense } from "react";
import "./App.scss";
import {
  Route,
  BrowserRouter as Router,
  useLocation,
  Routes,
} from "react-router-dom";
import NavbarComponent from "./components/organisms/Navbar/NavbarComponent";
import HomeComponent from "./components/pages/Home/HomeComponent";
import Footer from "./components/organisms/Footer/Footer";
import { ScrollTop } from "./components/atoms/scrollTop";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "react-medium-image-zoom/dist/styles.css";

import { Fab } from "@mui/material";
import BackgroundEffects from "./components/organisms/BackgroundEffects/BackgroundEffects";
import UpperNavComponent from "./components/UpperNav/UpperNav.component";

// lazy loading of components

const routes = [
  {
    name: "",
    path: "/about",
    component: lazy(() => import("./components/pages/About/About")),
  },

  {
    name: "",
    path: "/products",
    component: lazy(() => import("./components/pages/Products/Products")),
  },
  {
    name: "",
    path: "/approvals",
    component: lazy(() => import("./components/pages/Approvals/Approvals")),
  },

  {
    name: "",
    path: "/product-details/:id/:name",
    component: lazy(
      () => import("./components/pages/ProductDetails/ProductDetails")
    ),
  },
  {
    name: "",
    path: "/contact-us",
    component: lazy(() => import("./components/pages/ContactUs/ContactUs")),
  },
];

function GoToTop(props: any) {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return null;
}
function App(props: any) {
  return (
    <>
      <Router>
        {window.innerWidth >= 768 && <UpperNavComponent />}
        <NavbarComponent />
        <GoToTop />
        <Routes>
          <Route path="/" element={<HomeComponent />}></Route>

          {routes.map((item, index) => {
            return (
              <Route
                key={index}
                path={item.path}
                element={
                  <Suspense fallback={"Loading..."}>
                    <item.component />
                  </Suspense>
                }
              ></Route>
            );
          })}
        </Routes>

        <Footer />
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <BackgroundEffects />
      </Router>
    </>
  );
}

export default App;
