import { Button, Card, CardContent, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import styles from "./HomeComponent.module.scss";
import { Fade } from "react-awesome-reveal";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import VerifiedIcon from "@mui/icons-material/Verified";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AddIcon from "@mui/icons-material/Add";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CountUp from "react-countup";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
import QuoteForm from "../../organisms/QuoteForm/QuoteForm";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { NavLink } from "react-router-dom";
import { products } from "../../../shared/data/data";
import Grid from "@mui/material/Grid";
import * as React from "react";

const HomeComponent = (props: any) => {
  let mainWindow: any;

  if (typeof window !== "undefined") {
    // Client-side-only code
    mainWindow = window;
  } else {
    mainWindow = null;
  }

  function formatName(name: string) {
    return name.replaceAll(/[ /]/g, "-");
  }

  let clients = [
    "/images/home/logo2.jpg",
    "/images/home/logo3.jpg",
    "/images/home/logo4.jpg",
    "/images/home/logo5.jpg",
    "/images/home/logo6.jpg",
    "/images/home/logo7.jpg",
    "/images/home/logo8.jpg",
  ];

  const trigger = useScrollTrigger({
    target: mainWindow ? mainWindow : undefined,
    disableHysteresis: true,
    threshold: 1200,
  });

  return (
    <>
      <div className={styles.main}>
        <div className={styles.section_1}>
          {/* <Fade triggerOnce={true} cascade={true} direction="up">
            {/* <img src="/neonlogo.png" alt="neonlogo" height="150" width="150" /> 
            <Typography variant="h2" className={styles.intro_big}>
              Welcome to Neon Controls Pvt. Ltd.
            </Typography>

            <Typography variant="h5" className={styles.intro_small + " bold"}>
              An ISO Certified: 2015 CE Company.
            </Typography>

            <p className={styles.description}>
              We are a strong technology based electronic company having an
              excellent track record for high state of the art quality
              instrument in the industrial process control industry. The company
              situated in the industrial city of India is well managed by
              experts.
            </p>

            <Button variant="contained" className="theme_btn_primary">
              Learn more
            </Button>
          </Fade> */}
          <Container maxWidth="xl">
            <div className={styles.horizontal_scroll}>
              <Swiper
                // install Swiper modules
                // effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                initialSlide={1}
                // coverflowEffect={{
                //   rotate: 0,
                //   stretch: 100,
                //   depth: 200,
                //   modifier: 1,
                //   slideShadows: true,
                // }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 1,
                  },
                }}
                navigation={true}
                modules={[
                  // EffectCoverflow,
                  Pagination,
                  Autoplay,
                  Navigation,
                ]}
                // autoplay={{ delay: 3500, disableOnInteraction: true }}
                pagination={true}
                className="mySwiper"
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
              >
                {products.map((item2: any, index2: number) => {
                  return (
                    item2 &&
                    item2.name != "Product Range Catalog 2023" && (
                      <SwiperSlide className={styles.swiper_slide} key={index2}>
                        <div className={styles.slider_content}>
                          <div className={styles.slider_content_one}>
                            <Fade direction="up" cascade={true}>
                              <Typography
                                variant="h5"
                                sx={{ textDecoration: "uppercase" }}
                                className={styles.slider_content_title}
                              >
                                {item2.name}
                              </Typography>

                              <div
                                className={styles.slider_content_description}
                              >
                                <ul>
                                  {item2["Features"]
                                    ? item2["Features"]
                                        ?.slice(0, 5)
                                        .map((item3: any, index3: number) => {
                                          return <li key={index3}>{item3}</li>;
                                        })
                                    : item2["General Specifications"]
                                    ? item2["General Specifications"]
                                        ?.slice(0, 5)
                                        .map((item3: any, index3: number) => {
                                          return <li key={index3}>{item3}</li>;
                                        })
                                    : item2["Accessories"]
                                    ? item2["Accessories"]
                                        ?.slice(0, 5)
                                        .map((item3: any, index3: number) => {
                                          return <li key={index3}>{item3}</li>;
                                        })
                                    : item2["General"]}
                                </ul>
                              </div>
                            </Fade>

                            <NavLink
                              to={
                                "/product-details/" +
                                (index2 + 1) +
                                "/" +
                                formatName(item2.name)
                              }
                            >
                              <Button
                                variant="contained"
                                className="theme_btn_primary"
                              >
                                Read More
                              </Button>
                            </NavLink>
                          </div>

                          <div className={styles.slider_content_two}>
                            <img
                              src={item2.images[0]}
                              height="350"
                              alt="product img"
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  );
                })}
              </Swiper>
            </div>
          </Container>
        </div>

        <div className={styles.section_2}>
          <Container maxWidth="xl">
            {/* <div className={styles.intro}>
              <Typography variant="h5">
                {" "}
                We offer a large number of digital measuring, testing equipments
                and systems to be used in various industry.
              </Typography>
            </div> */}

            <div className={styles.section_2_content}>
              <Fade triggerOnce={true} direction="up">
                <Card className={styles.card}>
                  <CardContent className={styles.card_content}>
                    <WorkspacePremiumIcon />{" "}
                    <Typography variant="h5"> Assured Quality</Typography>
                    <p>
                      Delighting Customers with Hi-Quality. Customized Products
                      and Services in Energy Management System.
                    </p>
                  </CardContent>
                </Card>
                <Card className={styles.card}>
                  <CardContent className={styles.card_content}>
                    <RocketLaunchIcon />{" "}
                    <Typography variant="h5">
                      {" "}
                      State-of-the-art technology
                    </Typography>
                    <p>
                      High quality and reliable products available from neon
                      controls.
                    </p>
                  </CardContent>
                </Card>
                <Card className={styles.card}>
                  <CardContent className={styles.card_content}>
                    <VerifiedIcon />{" "}
                    <Typography variant="h5">
                      {" "}
                      Reputed manufacturer & service provider
                    </Typography>
                    <p>
                      We are committed to deliver Hi-Quality innovative products
                      with world class service support to the delight and
                      satisfaction of our customers.
                    </p>
                  </CardContent>
                </Card>
                {/* <Card className={styles.card}>
                  <CardContent className={styles.card_content}>
                    <SupportAgentIcon />{" "}
                    <Typography variant="h5"> After market support</Typography>
                  </CardContent>
                </Card> */}
                <Card className={styles.card}>
                  <CardContent className={styles.card_content}>
                    <span>
                      <EngineeringIcon /> <AddIcon className={styles.addIcon} />{" "}
                      <LocalShippingIcon />{" "}
                    </span>
                    <Typography variant="h5"> Efficiency</Typography>
                    <p>
                      Efficiency is the capability of an enterprise to deliver
                      products or services to its customers in the most
                      cost-effective manner possible
                    </p>
                  </CardContent>
                </Card>
                {/* <Card className={styles.card}>
                  <CardContent className={styles.card_content}>
                    <span>
                      &
                    </span>
                    <Typography variant="h5">
                      {" "}
                      Skilled Engineers & Distributors
                    </Typography>
                  </CardContent>
                </Card> */}
              </Fade>
            </div>
          </Container>
        </div>

        <div className={styles.section_4}>
          <Container maxWidth="xl">
            <div className={styles.intro}>
              <Typography variant="h5"> About Neon Controls</Typography>
              <hr />
            </div>
            <div className={styles.about_seller}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={7}>
                  <p>
                    We take this opportunity to introduce ourselves as a reputed
                    manufacturer and service provider in the areas at power
                    factor control, power and Energy management and power factor
                    management.
                    <br />
                    <br />
                    <span className="neyon">
                      <span>n</span>eon
                    </span>{" "}
                    Controls is a strong technology based electronic company
                    having an excellent track record for high state of the art
                    quality instrument in the industrial process control
                    industry. The company situated in the industrial city of
                    India is well managed by experts.
                    <br />
                    <br />
                    <span className="neyon">
                      <span>n</span>eon
                    </span>{" "}
                    Controls is an ISO 9001: 2015{" "}
                    <img
                      src="/images/c1.jpg"
                      alt="neoncertificates"
                      height="18"
                    />{" "}
                    Certified company{" "}
                  </p>

                  <NavLink to="/about">
                    <Button className="theme_btn_primary" variant="contained">
                      Read More
                    </Button>
                  </NavLink>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <div className={styles.bestsellers}>
                    <Typography variant="h5"> Our Bestsellers</Typography>
                    <div className={styles.horizontal_scroll}>
                      <Swiper
                        // install Swiper modules
                        // effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={1}
                        initialSlide={1}
                        // coverflowEffect={{
                        //   rotate: 0,
                        //   stretch: 100,
                        //   depth: 200,
                        //   modifier: 1,
                        //   slideShadows: true,
                        // }}
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                            spaceBetween: 1,
                          },
                          // when window width is >= 480px
                          480: {
                            slidesPerView: 1,
                            spaceBetween: 1,
                          },
                          // when window width is >= 640px
                          640: {
                            slidesPerView: 1,
                            spaceBetween: 1,
                          },
                          1024: {
                            slidesPerView: 1,
                            spaceBetween: 1,
                          },
                        }}
                        navigation={true}
                        modules={[
                          // EffectCoverflow,
                          Pagination,
                          Autoplay,
                          Navigation,
                        ]}
                        loop={true}
                        autoplay={{ delay: 3500, disableOnInteraction: true }}
                        pagination={true}
                        className="mySwiper"
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log("slide change")}
                      >
                        {products.map((item: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              {item.isBestSeller && (
                                <SwiperSlide className={styles.swiper_slide}>
                                  <img
                                    className={styles.client_img}
                                    alt="client img"
                                    height="300"
                                    src={item.images[0]}
                                  />
                                  <Typography variant="h6">
                                    {item.name}
                                  </Typography>
                                </SwiperSlide>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <div className={styles.section_3}>
          {trigger && (
            <>
              <Fade triggerOnce={true} direction="up">
                <Typography variant="h3">
                  <CountUp duration={3} end={10000} /> + buyers around the world{" "}
                </Typography>
              </Fade>

              <div className={styles.stats}>
                <Fade triggerOnce={true} direction="up">
                  <div className={styles.stat_content}>
                    <span>
                      <Typography variant="h5">
                        <CountUp duration={3} end={1500} /> +
                      </Typography>
                    </span>
                    <Typography variant="h5">Happy Customers</Typography>
                  </div>
                  <div className={styles.stat_content}>
                    <span>
                      <Typography variant="h5">
                        <CountUp duration={3} end={20} /> +
                      </Typography>
                    </span>
                    <Typography variant="h5">Products</Typography>
                  </div>
                  <div className={styles.stat_content}>
                    <span>
                      <Typography variant="h5">
                        <CountUp duration={3} end={7000} /> +
                      </Typography>
                    </span>
                    <Typography variant="h5">Units Sold</Typography>
                  </div>
                </Fade>
              </div>
            </>
          )}
        </div>
        <div className={styles.section_5}>
          <Container maxWidth="xl">
            <div>
              <Typography variant="h5" className="theme_color_primary">
                Our Clients
              </Typography>
              <hr />
            </div>

            <div className={styles.horizontal_scroll}>
              <Swiper
                // install Swiper modules
                // effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={5}
                initialSlide={1}
                // coverflowEffect={{
                //   rotate: 0,
                //   stretch: 100,
                //   depth: 200,
                //   modifier: 1,
                //   slideShadows: true,
                // }}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 1,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 1,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 1,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 1,
                  },
                }}
                navigation={true}
                modules={[
                  // EffectCoverflow,
                  Pagination,
                  Autoplay,
                  Navigation,
                ]}
                loop={true}
                autoplay={{ delay: 3500, disableOnInteraction: true }}
                pagination={true}
                className="mySwiper"
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
              >
                {clients.map((item: string, index: number) => {
                  return (
                    <SwiperSlide className={styles.swiper_slide} key={index}>
                      <img alt="client img" height="100" src={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
