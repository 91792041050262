import styles from "./NavbarComponent.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useEffect } from "react";
import React from "react";
import { products } from "../../../shared/data/data";
export const routes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Products",
    path: "/products",
  },
  {
    name: "Approvals",
    path: "/approvals",
  },

  {
    name: "Contact Us",
    path: "/contact-us",
  },
];

const NavbarComponent = (props: any) => {
  let mainWindow = window;

  function formatName(name: string) {
    return name.replaceAll(/[ /]/g, "-");
  }

  const trigger: any = useScrollTrigger({
    target: mainWindow ? mainWindow : undefined,
    disableHysteresis: true,
    threshold: 300,
  });

  // let { pathname } = useLocation();

  function closeNav(innerListClick?: boolean) {
    if (window.innerWidth > 768 || innerListClick) {
      document.getElementById("main_nav")?.classList?.remove("show");
    }
  }

  useEffect(() => {
    document?.addEventListener("DOMContentLoaded", function () {
      // make it as accordion for smaller screens
      if (window.innerWidth > 992) {
        document.querySelectorAll(".navbar .nav-item").forEach((everyitem) => {
          everyitem.addEventListener("mouseover", (e) => {
            let el_link = this.querySelector("a[data-bs-toggle]");

            if (el_link != null) {
              let nextEl = el_link.nextElementSibling;
              el_link.classList.add("show");
              nextEl?.classList.add("show");
            }
          });
          everyitem.addEventListener("mouseleave", (e) => {
            let el_link = this.querySelector("a[data-bs-toggle]");

            if (el_link != null) {
              let nextEl = el_link.nextElementSibling;
              el_link.classList.remove("show");
              nextEl?.classList.remove("show");
            }
          });
        });
      }
      // end if innerWidth
    });
    // DOMContentLoaded  end
  });

  return (
    <>
      <nav
        className={styles.navbar + " navbar navbar-expand-lg navbar-dark "}
        style={{
          position: trigger && window.innerWidth > 1000 ? "fixed" : "initial",
          width: "100%",
          top: "0",
          zIndex: "2",
        }}
      >
        {window.innerWidth <= 768 && (
          <NavLink to="/" className={styles["navbar-brand"] + " navbar-brand"}>
            <div className={styles.neonlogo_container}>
              <img
                src="/neonlogo.png"
                alt="neonlogo"
                className={styles.neonlogo}
              />
            </div>
          </NavLink>
        )}
        <button
          className={styles["navbar-toggler"] + " navbar-toggler"}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={styles.main_nav + " collapse navbar-collapse"}
          id="main_nav"
        >
          <ul className={styles.navbar_nav + " navbar-nav"}>
            {routes.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.name == "Products" ? (
                    <li className={styles.nav_item + " nav-item"}>
                      <NavLink
                        to={item.path}
                        onClick={() => closeNav()}
                        className="nav-link  dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        {item.name}
                      </NavLink>
                      <ul
                        className="dropdown-menu"
                        style={{ top: trigger ? "initial" : "20% !important" }}
                      >
                        {products.map((item: any, index: number) => {
                          return (
                            <li key={index}>
                              <NavLink
                                to={
                                  "/product-details/" +
                                  (index + 1) +
                                  "/" +
                                  formatName(item.name)
                                }
                                onClick={() => closeNav(true)}
                                className={(isActive) =>
                                  styles["nav-link"] +
                                  (!isActive.isActive
                                    ? " nav-link dropdown-item"
                                    : " nav-link activeLink dropdown-item")
                                }
                              >
                                {item.name}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ) : (
                    <li className={styles.nav_item + " nav-item"}>
                      <NavLink
                        to={item.path}
                        onClick={() => closeNav()}
                        className={(isActive) =>
                          styles["nav-link"] +
                          (!isActive.isActive
                            ? " nav-link"
                            : " nav-link activeLink")
                        }
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavbarComponent;
