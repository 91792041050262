const products = [
  {
    name: "AUTOMATIC POWER FACTOR CONTROL RELAY 3 PHASE, 3CT TYPE -3330",
    file: "/files/A P F C R 3PHASE, 3CT TYPE 3330..2022.pdf",
    isBestSeller: false,
    images: ["/images/products/26.png", "/images/products/1.png"],

    "Electrical Characteristics": {},
    Features: [
      "Field Proven Design.",
      "Three Phase Four Wire Three CT Operations.",
      "Compact DIN Std Flush mounting case 144mm x 144mm x 110mm.",
      "Real Time four digit display of Power Factor for Accurate Measurement.",
      "Built in Auto/Manual Control.",
      "Available in 4, 8, 12, 16 Switching stages.",
      "Sensing through CT on individual Phase.",
      "Auto Detection of Low Current(t.01) and Wrong External Connection(t.02).",
      "All Setting by Feature Touch Push Buttons.",
    ],
    "Digital Settings For": [
      "Number of Stages.",
      "Target PF from 0.850 Lag to 0.850 Lead.",
      "Dead Band From 4 deg to 14 deg Electrical.",
      "Time to Switch a Stage On.",
      "Time to Switch a Stage Off.",
      "Digital Setting of Discharge Time by Logic Timer for Each Stage to allow full discharge of the stage capacitor before re-switching.",
      "Option of HT Control Through PT.",
      "Options of Output for Thyristor Switching.",
    ],
    Specifications: {
      Voltage: "415V +/- 10% Three Phase Four Wire. (110V for HT application.)",
      Current: "0.05 to 5Amp through Secondary of each CTs on each Phase.",
      "Low current":
        "APFCR will indicate t.01 and switch OFF all the stages that were ON in sequence only when current is Low or absent in all three Phases.",

      "Wrong Connection":
        "All three Phases of CTs Connection must be correct for the APFCR to run correctly. The display indicates t.02 if CTs and Phase Connections are Wrong. The operation of APFCR is locked out.",

      "Number of stages":
        "You can set the number of Stages from Four to Maximum.",
    },

    "Digital setting by Feather touch Push button as under": {
      "Target P.F(Setting TPF)": "Settable from 0.850 Lag to 0.850 lead.",
      "Dead Band(Setting DB)": "Settable from 4 deg to 14 deg Electrical.",
    },
    "Time to Switch Stages": {
      "Switch ON(Setting TON)": " Settable from 2 seconds to 50 seconds.",
      "Switch OFF(Setting TOFF)": "Settable from 2 seconds to 50 seconds.",
      "Discharge Time(Setting TDIS)":
        " Settable from 15 seconds to 300 seconds.",
      "Contact Ratting":
        "Each Switching contact is rated for maxima of 230V, 5Amps AC resistive loads.",
      Dimensions:
        "Front Bezzel : 144 mm x 144 mm, Panel Cutout : 138 mm x 138 mm. Depth Behind Panel : 110 mm including terminals.",
      Weight: "Approx. 1.2 Kg.",
    },
    General: `This APFCR type 3330 is what is known as ‘three CT’. It measures the power factor by three phase triple element
    basis. It thus takes care of three phase balanced as well as unbalanced loads.

    This APFCR will switch three phase capacitors. The load currents in the three phases of such capacitors are balanced.
    If you are using three phase capacitors to control loads that are permanently unbalanced, you will get leading power
    factor always in the phases that carry relatively less load current. Leading power factor can cause fluctuating voltage
    by an abnormal rise in voltage (positive regulation) depending upon the impedance of the system.

    For such permanently unbalanced loads you should use three separate single phase systems using three APFCRs
    type 1305 and single phase capacitors in the three phases, independently.

    The Controller senses the total resultant power factor of the system and switches the capacitors through the
    appropriate control gear in the panel so as to correct the power factor to the required level.
    
    `,
  },
  {
    name: "AUTOMATIC POWER FACTOR CONTROL RELAY TYPE 1305",
    file: "/files/A P F C R TYPE 1305...2022.pdf",
    isBestSeller: true,
    images: ["/images/products/2.png"],

    "Electrical Characteristics": {},
    Features: [
      "Field Proven Design.",
      "Compact DIN Std flush mounting case 144mm x 144 mm x 100mm. Real time four digit display of Power Factor for accurate Measurement.",
      "Built in Auto / Manual Control.",
      "Available in 4, 8, 12, 16 Switching stages Input.",
      "Sensing Through CT on Individual Phase.",
      "Auto Detection of Low Current (Display Reads t.01) and Wrong External Connections (Display Reads t.02).",
      "Low Current Cutoff at 1% of CT Current.",
      "All Settings by Feather Touch push buttons.",
    ],
    "Digital Settings For": [
      "Number of Stages.",
      "Target PF from 0.850 Lag to 0.850 Lead",
      "DeadBand from 4deg to14deg Electrical",
      "Time to Switch a StageOn.",
      "Time to Switch a Stage Off.",
      "Digital Setting of Discharge Time by Logic Timer for Each Stage to allow full discharge of the stage capacitor before re-switching.",
      "Three Phase Four Wire Operation",
      "Single Phase Operation Optional",
      "ThreePhase HTOperation Optional.",
    ],

    "Digital setting by Feather touch Push button as under": {
      "Number of Steps":
        "You can set the number of Stage from 4 to the maximum",
      "Target PF": "Settable from 0.850 Lag to 0.850 Lead",
      "Dead Band": "Settable from 4 deg to14 deg electrical",
    },
    "Time to Switch Stages": {
      "Switch On": "Settable from 2 seconds to 60 seconds",
      "Switch off": "Independently Settable from 2 seconds to 60 seconds",
      "Discharge Time": "Settable form 15 seconds to 300 seconds",
      "Contact Rating":
        "Each switching contact is rated for maxima of 230V,5Amps AC resistive loads.",
      Dimensions:
        "Front Bezzel: 144mmx144mm, Panel Cutout: 138mmx138mm Depth Behind Panel: 120mm including terminals.",
      Weight: "Approx. 1.0 Kg",
    },
    Specifications: {
      Voltage:
        "415V +/- 10% Three Phase Four Wire (110V for HT application and 240V Single Phase versions available on request)",

      Current: "Through CT on the Main Incomer 5 Amps secondary on R Phase",
      "Low Current Cutout":
        "Factory set a 1% Display reads t.01 when current is too low.",
      "Wrong Connection ":
        "The Three phases and the CT connections must be correct for the APFCR to run correctly. The Display indicates t.02 if these external connections are wrong.",
    },
    General: `Power Factor is an important electrical parameter needing control. State of the Art Microcontroller based APFCR
    type 1305 is specially designed to control the power factor of an installation by giving signals to switch capacitors
    on/off in sequence till the desired power factor is achieved.

    The APFCR is house in a compact flush mounting enclosure of size 144X144 DIN and has all the required indications
    arranged on the front panel.
    Shrouded (Green) connectors are provided at the rear of the case for external wiring.
    
    The APFCR is the center of the Automatic Power Factor Control Relay System. It switches the connected capacitors
    on and off so that the power factor is maintained at target value. The instrument is Microcontroller based for the
    complex functions required. It is compact, reliable using the latest Microcontroller technology.
    
    Neon APFCR type1305 is for use in all applications requiring accurate and reliable control of power factor. It is
    available for three phase as well as single phase application.
    
    The Single Phase Relay is used for unbalanced loads using three single phase APFC systems in star. This gives more
    accurate and meaningful control compared to a 3CT APFCR. When a 3CT APFCR is used, the load unbalance is
    straightway reflected as an unbalanced power factor which may lead to higher harmonics and higher transients.
    
    The APFCR has digital settings to facilitate use in wide variety of applications.
    
    `,
  },
  {
    name: "MICROCONTROL AUTOMATIC POWER FACTOR RELAY TYPE 1341",
    file: "/files/Microcontrol A P F R Type 1341...2022.pdf",
    isBestSeller: false,
    images: ["/images/products/3.png"],

    "Electrical Characteristics": {},
    Features: [
      "Field Proven Design.",
      "Compact DIN Std flush mounting case 96mm x 96mm width with 70mm depth.",
      "Low cost efficient A P F C Relay.",
      "Built in all Factory Set Parameter.",
      "Built in Digital Timer for fast operation to ensure capacitor safety.",
      "Available in 4, 6, 8, Stages LINEAR sequence.",
      "Auto Detection of Low Current and Wrong Connection indication.",
      "Digital P F Meter for accurate measurement.",
      "Built in low current cut off at 1%.",
    ],
    "Digital Settings For": [],

    "Digital setting by Feather touch Push button as under": {},
    "Time to Switch Stages": {},
    Specifications: {
      Voltage:
        "415V +/- 10% Three Phase Four Wire (110V for HT application and 240V Single Phase versions available on request)",

      Current: "Through CT on the Main Incomer 5 Amps secondary on R Phase",
      "Low Current Cutout":
        "Factory set a 1% Display reads t.01 when current is too low.",
      "Wrong Connection":
        "The Three phases and the CT polarity connections must be correct for the APFCR to run correctly. The Display indicates t.02 if these external connections are wrong. This conditions persists till the APFCR is switched off and correct connections are made.",

      Meter:
        "4 Digit LED Display shows Power Factor from 0.000 Lag to 0.000 Lead.",
      Limits:
        "The capacitors are switched off sequently when PF goes above 0.990 Lead.",
      Timing:
        "The timing between switching of the stages is 5 seconds. A capacitor once switched off should not be switched on immediately. Each stage has individual timer to ensure delayed switch on after switch off.",

      "Switching Stages": "Standard models are for 4, 6, or 8 stages.",
      "Contact Rating":
        "Each switching contact is rated for maxima of 230V, 5 Amps AC resistive loads.",
      Dimension:
        "Front Bezel : 96mm x 96mm; Panel Cutout : 92mm x 92mm Depth Behind Panel : 70 mm including terminals.",

      Weight: "Approx 1 kg.",
    },
    General: `Power Factor is an important electrical parameter needing control. State of the Art Microcontroller based APFCR type
    1341 is specially designed to control the power factor of an installation by giving signals to switch capacitors on/off in
    sequence till the desired power factor is achieved.

    The APFCR is house in a compact flush mounting enclosure of size 96 x 96 DIN and has all the required indications
    arranged on the front panel.
    Shrouded (Green) connectors are provided at the rear of the case for external wiring.

    TheAPFCR is the center of the Automatic PowerFactor Control Relay System.It switches the connected capacitors
    on and off so that the power factor is maintained at target value. The instrument is Microcontrollerbased for the
    complex functions required. It is compact, reliable using the latest Microcontroller technology.

    Neon APFCR type 1341 is for use in all applications requiring accurate and reliable control of power factor. It is
    available for three phase as well as single phase application.

    The Single Phase Relay is used for unbalanced loads using three single phase APFC systems in star. This gives more
    accurate and meaningful control compared to a 3CT APFCR. When a 3CT APFCR is used, the load unbalance is
    straightway reflected as an unbalanced power factor which may lead to higher harmonics and higher transients.

    The APFCR has Digital Settings to facilitate use in wide variety of applications.
    
    `,
  },
  {
    name: "AUTOMATIC POWER FACTOR CONTROL RELAY TYPE 1350",
    file: "/files/A P F C R TYPE 1350...2022.pdf",
    isBestSeller: true,
    images: ["/images/products/4.png"],

    "Electrical Characteristics": {},
    Features: [
      "Field Proven Design",
      "Real time four digits power factor display",
      "Available in 4, 6, & 8 Switching stages",
      "Input Sensing Through CT on Individual Phase.",
      "Auto Detection of Low Current (Display Reads t.01)",
      "Wrong External Connections (Display Reads t.02)",
      "Low Current Cutoff",
      "All Settings by Feather Touch push buttons",
    ],
    "Digital Settings For": [
      "Number of Stages",
      "Target P F",
      "Dead Band",
      "Time to Switch a Stage On",
      "Time to Switch a Stage Off",
      "Digital Setting of Discharge Time by Logic Timer for Each Stage to allow full discharge of the stage capacitor before re-switching",
      "Three Phase Four Wire Operation",
      "Single Phase Operation Optional",
      "Three Phase HT Operation",
    ],

    "Digital setting by Feather touch Push button as under": {
      "Number of Steps":
        "You can set the number of Stage from 4 to the maximum of 10 stages",
      "Target PF": "Settable from 0.850 Lag to 0.850 Lead",
      "Dead Band": "Settable from 4 deg to 14 deg electrical",
    },
    "Time to Switch Stages": {
      "Switch On": "Independently Set ta ble from 2 seconds to 15 seconds",
      "Switch off": "Independently Settable from 2 seconds to 15 seconds",
      "Discharge Time": "Settable form 15 seconds to 300 seconds",
      "Contact Rating":
        "Each switching contact is rated for maxima of 230V, 5Amps. AC resistive loads.",
      Dimensions:
        "Front Bezzel : 96mmx96mm, Panel Cut out: 92mmx92mm Depth Behind Panel: 70 mm including terminals.",
      Weight: "Approx. 500 gm.",
    },
    Specifications: {
      Voltage:
        "415V +/- 10% Three Phase Four Wire (110V for HT application and 240V Single Phase versions available on request)",

      Current: "Through CT on the Main Incomer 5 Amps secondary on R Phase",
      "Low Current Cutout":
        "Factory set a 1% Display reads t.01 when current is too low.",
      "Wrong Connection":
        "The Three phases and the CT polarity connections must be correct for the APFCR to run correctly. The Display indicates t.02 if these external connections are wrong. This conditions persists till the APFCR is switched off and correct connections are made.",
    },
    General: `Power Factor is an important electrical parameter needing control. State of the Art Microcontroller based APFCR type
    1350 is specially designed to control the power factor of an installation by giving signals to switch capacitors on/off in
    sequence till the desired power factor is achieved.

    The APFCR is house in a compact flush mounting enclosure of size 96 x 96 DIN and has all the required indications
    arranged on the front panel.
    Shrouded (Green) connectors are provided at the rear of the case for external wiring.

    The APFCR is the center of the Automatic PowerFactor Control Relay System.It switches the connected capacitors
    on and off so that the power factor is maintained at target value. The instrument is Microcontroller based for the
    complex functions required. It is compact, reliable using the latest Microcontroller technology.

    Neon APFCR type 1350 is for use in all applications requiring accurate and reliable control of power factor. It is
    available for three phase as well as single phase application.

    The Single Phase Relay is used for unbalanced loads using three single phase APFC systems in star. This gives more
    accurate and meaningful control compared to a 3CT APFCR. When a 3CT APFCR is used, the load unbalance is
    straightway reflected as an unbalanced power factor which may lead to higher harmonics and higher transients.

    The APFCR has Digital Settings to facilitate use in wide variety of applications.
    
    `,
  },
  {
    name: "DIGITAL PANEL METERS Voltmeter / Ammeter / VAF Meter",
    file: "/files/DIGITAL PANEL METERS....2022.pdf",
    isBestSeller: true,
    images: [
      "/images/products/5.png",
      "/images/products/7.png",
      "/images/products/27.jpg",
    ],
    Intro: `Neon Controls the Basic Series Meters are low cost microprocessor based metering instruments with 24 Bit ADC and SMPS /
    Capacitor based power supply unit. The meters have been designed to meet the harsh conditions of Indian Utilities and have
    following special features`,
    "Electrical Characteristics": {
      "Type of measurement": "True RMS AT 50Hz",
      "Current and voltage": "Class - 1.0",
      "Active Power": "Class - 1.0",
      "Reactive Power": "Class - 2.0",
      "Apparent Power": "Class - 1.0",
      Frequency: "Class - 1.0",
      "Power Factory": "Class - 1.0",
      "Active Energy": "Class - 1.0",
      "Reactive Energy": "Class - 2.0",
      "Apparent Energy": "Class - 1.0",
      "Demand Energy": "30 Min. Integration period",
      note: "*Additional error for meter input current below 100 mA",
      "Date update rate": "1sec",
      "Input Voltage": "V1, V2, V3, Vn",
      "Measured Voltage":
        "80 - 480 V AC L-L without PTs Upto 999kV with external Pts",

      "Permissible Overload": "1.10 Un(480 V L-L)",
      "Frequency range": "50Hz +/- 5%",
      "Primary CT": "1 A - 99.0 kA",
      "Secondary CT": "1 A - 5 A",
      "Measurement range": "40 mA - 6 A",
      "Permissible overload": "6 A continuous",
      Burden: "As per IS",
      Impedance: "As per IS",
      "AC Power Supply": "100 - 277 V DC",
      "DC Power Supply": "100 - 277 V DC",
    },
    "Display Characteristics": {
      "Integrated LED display": `View 3 parameter together on 3
line, 4 digits per line display.
Auto-scaling capability for kilo,
Mega values User-selectable
default display page. Password
protection for setup parameters.`,
    },
    "Mechanical Characteristics": {
      Weight: `0.500Kg to 0.250Kg (Shipping)
Dimensions : Bezel: 96 x 96 mm
Depth: 65 mm behind bezel
Panel cutout: 92 x 92 mm`,
    },

    "Environmental Characteristics": {
      "Operating Temperature": `4°C to 60°C
Storage Temperature : 4°C to 70°C
Humidity rating : 5 to 60% RH non- condensing`,
    },
    Communication: {
      "RS-485 port": `2 terminals only
Baud rate: 9600bps
Protocols: Modbus`,
    },
    Features: [
      "Robust Design",
      "4 Digit Red Bright FND Display",
      "Programmable on site CT ratio",
      "Programmable on site PT ratio",
      "Password Protection",
      "LED Indication for Healthy Phase",
    ],
    "Digital Settings For": [
      "Number of Stages",
      "Target P F",
      "Dead Band",
      "Time to Switch a Stage On",
      "Time to Switch a Stage Off",
      "Digital Setting of Discharge Time by Logic Timer for Each Stage to allow full discharge of the stage capacitor before re-switching",
      "Three Phase Four Wire Operation",
      "Single Phase Operation Optional",
      "Three Phase HT Operation",
    ],
    Applications: [
      "Electrical Control Panels",
      "Motor Control Panels",
      "DC Drive Control Panels",
      "Battery Chargers",
      "HT / LT Panels",
    ],
  },
  {
    name: "MICROCONTROL BASED DIGITAL SET POINT RELAY FOR CURRENT MRD-A1",
    file: "/files/DIGITAL SET POINT MRD-A1..2022.pdf",
    isBestSeller: true,
    images: ["/images/products/8.png"],
    Intro: `Neon instruments are used for variety of controls, alarm and tripping applications.

    These relay are field proven with excellent sensitivity and are with two independent outputs and are used as under
    current and over current controlling operations. The two independent outputs are in programmable in a specific
    range i.e. the range if it is 200A. This facility of having the liberty to set the required current is added advantage in
    the digital advantage in the digital meters of these models available in the market. The set points are field settable
    within the range mentioned in the model.

    The advantage of these relay is that timer are inbuilt and have an inbuilt timer eliminates the need for an external
    timer and external wirings. The timers are programmable from O sec to 30 sec. The compact size of meter / relay
    also ensures minimum spaces. The auxiliary supply for the relay is 110V AC or 230V AC.`,

    Features: [
      "True RMS Measurement",
      "Very Successfully used as Demand Controllers at a fraction of the cost of other so called MD Controllers that provide a lot of unnecessary features to increase cost",
      "Real Time bright steady 14.5 mm LED Display of AC Current",
      "Easily settable standard T ratings from 30 Amps to 4000Amps",
      "Easy Settings of Operating points over 10% to 100% of CT rating",
      "Consistent performance by microcontroller",
      "Built in individual Settable Timers to delay switching of output relays to avoid nuisance operation",
      "Feather Touch Push Buttons",
      "Compact Size 96x96x60 mm DIN Standard Enclosure",
    ],
  },
  {
    name: "MICROCONTROL BASED DIGITAL SET POINT RELAY FOR VOLTAGE MRD-V1",
    file: "/files/DIGITAL SET POINT MRD V1...2022.pdf",
    isBestSeller: true,
    images: ["/images/products/9.png"],
    Intro: `Neon instruments are used forVariety of controls, alarm and tripping applications.

    These instruments are digital set points relay which have two independent out puts and can be use d to control the under
    voltage and over voltage operations. The two independent outputs are programmable / set in a range of 0VAC to
    600VACaccording to the application needed . The primary application of these relays are to monitor digitally the
    voltage where the voltage fluctuations affects the functioning of the machinery or apparatus. Both the set points are field
    settable from 0V to 600V.

    The advantage of these relay is that timer are inbuilt and have an inbuilt timer eliminates the need for an external timer
    and external wirings.The timers are programmable fromO sec to30 sec.The compact size of meter / relay also
    ensures minimum spaces. The auxiliary supply for the relay is110V AC or230VAC.`,

    Features: [
      "True RMS Measurement",
      "Perfectly used for Over-Voltage Alarm & Trip and Voltage Control.",
      "Also Available for PT Operated HT use - MRDV2",
      "MRDV2 - Easily settable standard PT ratings from 3.3 KV TO 66 KV",
      "Real Time bright steady 14.5 mm LED Display of AC Voltage",
      "Easy Settings of Operating points over 10% to 100% of FDS",
      "Consistent performance by microcontroller",
      "Built in individual Settable Timers to delay switching of output relays to avoid nuisance operation",
      "Feather Touch Push Buttons",
      "Compact Size 96x96x60 mm DIN Standard Enclosure",
    ],
  },
  {
    name: "True RMS Digital Meter Setpoint relay MRD-MULTI4",
    file: "/files/SET POINT RELAY MRD MLUTI 4...2022.pdf",
    isBestSeller: false,
    images: ["/images/products/10.png"],
    Features: [
      "This instrument is for use in Three Phase Four Wire Systems",
      "It measures Phase-Neutral Voltages in R, Y, B Phases - RMS",
      "It measures Currents in the three phases R, Y, B – RMS CT operated",
      "It measures Total Watts (KW) in the three phases – RMS CT operated",
    ],

    "System Considerations": `
    The Controller needs three phase four wire voltage connections.
    To enable the Controller to operate correctly:

    The R, Y, B, N voltage connections must be correct. Ensure this point positively. It is not enough to have the correct
    phase sequence alone, the actual phase connections must be correct and the voltage must be within plus/minus 10%
    of the nominal value specified for the Controller.

    The CTs must be of the correct rating commensurate with actual Load.
    All three CTs must have the same specifications.
    The CT connections must be correct i.e. R phase CT wires must be connected to R phase
    Current terminals of the instrument etc

    The polarities of the CTs must be correct.
    The setting of the CT rating must be equal to the rating of the CTs installed in the system
    The instrument derives power from the Voltage of R phase.
    `,
    "Setting for CT Rating": `
    You can set all the standard CT ratings from 30 Amps to 4000 Amps. Secondary is always 5 Amps. (1 Amp models on
    special order at extra charges). The readings for Amps, KW & KVA as well as the settings for them will be shown at
    appropriate scale on the LCD display.`,
    "Set-Points": `
    Two relays are provided. The NC, Common and NO points of each relay are brought out to
    terminals at rear for connection to your Alarm and Trip arrangements.
    10% to 100% Digital settings are provided for Current, KW and KVA.
    Voltage settings are from 180V to 260V AC RMS 50 Hz
    The Set-points for Current and voltage operate for each phase independently i.e. the corresponding relay will operate
    if any of the phase Voltages or Phase Current goes above the setting.
    The Set-pints for KW and KVA will operate on the basis of the measurement for all the three phases added together.
    If two or more of the parameters go beyond the Set-points – they will be shown on the display appropriately.`,
    Timers: `
    Each Set-point has a corresponding built in software Timer settable from 2 seconds to 60 seconds. The timer delays
    the operation of the corresponding relay from the detection of crossing of the Set-Point. This avoids nuisance signals
    occurring on transients.`,
    Size: `
    The instrument is housed in a DIN enclosure of 96 X 96 X 120 Depth behind panel size.`,
  },
  {
    name: "SMART MULTI-FUNCTION METER",
    file: "/files/SMART MULTI FUNCTION METER..2022.pdf",
    isBestSeller: false,
    images: [
      "/images/products/11.png",
      "/images/products/12.png",
      "/images/products/13.png",
    ],
    Intro: `Neon Controls Smart Multifunction Meters are based on 32 Bit, 100 Pin Microprocessor, with 24 Bit Six Channel
   Sigma Delta Front end Analog to Digital Converters. The measurement accuracy. The Measurement accuracy of Class
   0.2 an be achieved on the on the practical range of operation.

   Programmable Multifunction Meter is a versatile instrument used for controlling the electricity consumption and is
   extremely useful for putting up a limit on the drawn load options.
   `,
    "The Variants available are": [
      "TMFLR70 - Smart MF LCD Meter Cl - 0.5S",
      "TMFR50 - Smart MF LCD Meter Cl - 0.5.S",
      "TMFR42 - Advance MF 7 Segment Meter Cl - 0.1",
    ],

    Features: [
      "0.2 Class Accuracy of Active Energy",
      "32 Bit, 100 Pin, Processor",
      "1 Energy Pulse Output",
      "Real Time Waveform",
      "Voltage / Frequency Deviation",
      "Flicker / Transient",
      "Fundamental Energy",
      "Memory 8MB",
      "Various choice of Bright LCD display",
      "2 Digital input & 2 no's relay output",
      "Demand/Max./Min./Over-Limit alarm",
      "Load on/off hours",
      "Power on hours",
      "Bi-directional Energy",
      "Tariff Energy",
      "Four-Quadrant reactive energy",
      "Harmonics V/A 2nd to 63rd",
      "Total Harmonics Distortion (THD)",
      "Analog output (4-20 mA)",
    ],
    "Technical Specifications": {
      DISPLAY: {
        "Sfere 60": "LCD",
        "Sfere 70": "TFT",
      },
      ACCURACY: {
        "V/A ": "0.2%",
        "P/Q/S/PF ": " 0.2%",
        F: "+/-0.01 Hz",
        "+/-kWh ": " Class 0.2S",
        "+/-kVArh ": " Class 2",
        Insulation: "Signal, power, output terminal to shell resistance>100mΩ ",
        "Withstand Voltage":
          "Input and power > 2kV, Input and output > 1kV, Power and output > 2kV",
      },
      "VOLTAGE INPUT": {
        "Rated Input": "A 100V, A 380V",
        Overload: "Continuous: 1.2Vn Instantaneous",
        Burden: " ≤0.1VA(per phase)",
        Impedance: " ≥ 1.7 m Ohm",
        Frequency: " 45Hz - 65Hz",
      },
      "CURRENT INPUT": {
        "Rated Input": "AC1A , AC5A",
        Overload: "Continuous: 1.2In Instantaneous: 2.1ln/5s",
        "Burden ": "≤0.1VA(per phase)",
        "Impedance ": "≥ 20 m Ohm",
        "Operating Temperature": "25°C ~ 70°C",
        "Storage Temperature ": "30°C ~ 80°C",
        "Relative Humidity ": "≤93%",
        "Altitude ": "≤2500m",
      },
      "AUXILLARY SUPPLY": {
        "Working range ": "AC 80-270V 50/60Hz DC 100 - 350V",
        "Consumption ": "≤5VA",
      },
      "COMMUNICATION PORT": {
        "RS485, ModbusRTU,2": "Wire, up to 38.4kbps",
      },

      "ENERGY PULSE OUTPUT": {
        "1 Photo coupler output, pulse width": "(80+/-20%)ms",

        "2 AC wet contact rated at AC 5A/250V or DC 5A/30V, Isolation": "2kVAC",
      },

      INSULATION: {
        value: "≥ 2kVAC",
      },
      "IP DEGREE": {
        Front: "IP64",
        Rear: "IP20",
      },

      "OPERATING ENVIRONMENT": {
        Temperatures: "25°C ~ 65°C",
        Humidity: "≤ 93%",
        "no erosion gas, altitude": "≤2500m",

        Port: `RS-485 port communicates as per MODBUS Protocol &
        can be easily configured to an Enertrak GSM/GPRS
        modem for seamless & live data transmission to host`,
      },
    },
  },
  {
    name: "DIGITAL TAP POSITION METERS",
    file: "/files/Digital tap position indicator...2022.pdf",
    isBestSeller: false,
    images: ["/images/products/14.png"],
    Intro: `Neon Controls Digital Tap Position indicator, type NC-01 is a microcontroller based instruments used to indicate the Tap
    position of the power Transformer with On Load Tap-Changer.`,
    Features: [
      "Microcontroller Based Design.",
      "Automatic Detection of Number of Taps.",
      "1000 ohms /Step Resistances or User Defined.",
      "Flicker Free and clear Sev en segment LED display for long distance viewing.",
      "4-20mA DC Current Single Dual Output for SCADA connectivity.",
    ],
    SPECIFICATIONS: {
      "Model No.": "NC-01",
      "No. of Tap Position": "Up to 40 taps",
      "Type of Sensor":
        "10 ohms to 1 K ohms, per step resistance (Required Resistance to be specified)",
      "Analogue Output": "4 - 20 mA Single Dual Output (OPTIONAL)",
      "Display Format": "7 Segments LED 12.7mm High Red Display",
      "Power Consumption": "500 mill Watts",
      Accuracy: "+/-0.5%",
      Size: "96 mm x 96 mm",
      "Panel Output": "90 mm x 90 mm",
      "Operating Temperature": "0 - 50°C",
      "Auxiliary Supply":
        "110V or 230V AC + 10%, 50Hz 85V to 256V AC/DC (OPTIONAL)",
    },
  },
  {
    name: "HIGH VOLTAGE TEST SETS",
    file: "/files/AC HIGH VOLTAGE TEST KIT..2022.pdf",
    isBestSeller: true,
    images: ["/images/products/15.png", "/images/products/28.jpg"],
    Intro: `Neon Controls Hi-Pot tester HVT series offer wide range of test voltages to determine the withstand capacity of electrical
insulation for the normally occurring ov er voltage transient’s. This series has models which can generate both AC & DC Voltages
to eliminate need of two separate kits.

The manual / motorized controlled operation provides continuous variable output voltage. The Kits are provided with selectable
tripping current to identify breakdown voltage of different test objects. The timer is useful to set duration of test period. HV
indication & overload protection provided for safety of operator & Kit.

The HVT is used to verify quality of insulation between any equipments current carrying components & its enclosure or
insulation. The high voltage is applied to insulation of equipment under test, as defined as per voltage rating of equipment. The
resulting leakage current flowing through its insulation is measured. The set will give trip command if exceeded than its
predefined value limit`,

    Features: [
      "Continuously variable motorized/manual output from zero voltage starts with locking.",
      "Panel mounted controls and LED indicators.",
      "Analog/Digital voltmeter & Ammeter-for easy to read voltage and current.",
      "Output voltmeter with memory effect-easy to read the voltage after trip.",
      "Selection by selector switch trip current.",
      "Zero start interlock switch for every unit.",
      "Audio & Visual alarms.",
      "Fast acting over load circuit breaker.",
      "MCB-magnetic overload protection.",
      "Electronic fast acting over current tripping devices sensing H.T. current directly.",
      "Indication Mains ON, H.T. ON & H.T. OFF.",
      "Housed in M.S Powder coated steel cabinet with heavy duty handles.",
      "Air/oil cooled table mounting Portable construction",
    ],
    "Technical Specifications": {
      "Input voltage ": "230 Volts +/- 10% 50HZ.",
      "Output voltage ": "as per table below",
      "Output voltage Accuracy ": " +/- 3%",
      "Control ": "Motorized/Manual type",
      "Duty Cycle ": "2 minutes ON 15 minutes OFF.",
      "Interlocking ": "Zero-start interlocking for H.V. output",
    },

    Accessories: [
      "Mains Wire 3 Core, 2 meter Long.",
      "Inter Connecting Cable 4 Long",
      "Operating Manual.",
      "Works Calibration Report.",
      "Warranty Certificate",
    ],
    Note: [
      "AC High Voltage Test Set of other capacity, rating and duty cycle, other than the ones mentioned above, can also be designed. Please send us specification you require to that we can have it designed and send the details to you.",
      "Also AC and DC Combined High Voltage Test Sets can be involved. Please send us the specification you require so that we can have it designed and send the details to you.",
    ],
  },

  {
    name: "INSULATING OIL TESTING KIT",
    file: "/files/Insulating Ol Testing Kit..2022.pdf",
    isBestSeller: true,
    images: ["/images/products/16.png"],
    Intro: `Neon Controls Insulating Oil Testing Kit set is useful for testing dielectric strength of the insulating oils normally used in
    distribution and high voltage transformers. The set is ideal for speedy and accurate testing of oil under test room condition in
    accordance with IS:6792-1972, IEC 156 for oil testing`,

    TYPE: `
Manual operated to uniformly increase the output voltage between 0-50kV or 60kV or 100kV with the help of continuously
Variable autotransformer.`,
    INPUT: `
230/240 V AC, 50Hz, Single phase.`,
    OUTPUT: `
Continuously variable from 0-50kV or 60kV or 100kV with center tap earthed.`,
    CAPACITY: `
1000VA Intermittent rating.`,
    INDICATOR: `
Main ON, HT ON and HT OFF indicator Lamps.`,
    METERING: `
A Memory effect volt meter shows how the breakdown voltage even after the high voltage circuits tripped off connected to
LT side and calibrated in kV.`,

    "HT TRANSFORMER": `
High Voltage Transformer 230V/50kV or 60kV or 100kV with center Tap HV winding earthed. The Transformer designed to
yield approximately sinusoidal voltage Waveform and graded insulation is used. It is specially designed to with stand
frequent momentary spark overs or short circuit Conditions under which such testing transfomers are designed to operate, H.T.
winding of the transformer is epoxy resin cast type.`,
    "SAFETY FEATURES": `
Zero interlocking arrangement before energizing HT Transformer after each test.
A Transparent protective high impact Acrylic sheet cover for covering the HT side with suitable interlocking devices.
The set will not been energized unless the HT end is covered. The oil cell is placed with in the cover space between the HT
Terminals.`,
    "TEST VESSEL": `
The Test vessel (oil cell) fitted with two brass sphere of diameter between 12.7 to 13mm, pl aced at a distance of 4mm or
2.5mm on sides often vessel made from non-absorbentinulating material. Gap can be adjusted by spheres. Dimension are
liberal and generally as per IS:6792-1972, A 'go'-'nogo' gauge will be supplied along with the cell to adjust the gap distance.`,
    AUXILLARIES: `
The unit will have necessary switches, fuses indication lamps, push button operated contractor etc. for efficient operation of
unit.`,
    "GENERAL ARRANGEMNT": `
The set will be compressing of the H.T. transformer, control Circuits & Test cell with electrodes & gauge and will be supplied
in portable air-cooled sheet mental casing with powder coating of control cubical & H.T. Transformer`,

    Accessories: [
      "Manual operated to uniformly increase the output voltage between 0-50kV or 60kV or 100kV with the help of continuously Variable auto transformer. ",
      "230/240 V AC , 50Hz , Single phase. ",
      "Continuously variable from 0-50kV or 60kV or 100kV with center tap earthed. ",
      "1000VA intermittent rating. ",
      "Main ON, HT ON and HT OFF indicator lamps. ",
      "A memory effect volt meter shows how the breakdown voltage even after the high voltage circuits tripped off connected to LT side and calibrated in kV.",
    ],
    OPTIONAL: [
      "A Oil Tets with Manual and Motor operation, Digital meter and with Magnetic stirrer Facility also available on request at Extra Cost.",
      "The Oil Test Set with Printer Facility is also available on Request.",
    ],
  },

  {
    name: "MOTORIZED INSULATING OIL TESTING KIT",
    file: "/files/Insulating Ol Testing Kit..2022.pdf",
    isBestSeller: true,
    images: ["/images/products/22.png"],
    Intro: `Neon Controls Motorized insulating oil testing kit is useful for testing strength of the insulating oils normally used in distribution
    and high voltage transformers. The set is ideal for speedy and accurate testing of oil under test room condition in accordance with
    IS 6792-1972, IEC 156 oil testing.

    The Oil test method is in intended for the acceptance of new insulating oil at the time of their delivery, it is applicabl e, in principle,
    to all classes of new and used oils for transformer, circuit breakers, oil-filled cables and capacitors. `,

    Features: [
      "Oil Test Set is suitable for conducting oil breakdown test as outlined in IS 6792 :1972.",
      "Continuously variable output voltages.",
      "A Linear scaled A.C. rectifier type Voltmeter marked in kV to measure the output voltage.",
      "The output voltmeter is with memory effect i.e. even after HT supply trips off due to the failure of equipment under test",
      "The voltmeter continues to show the voltage at which test samples has failed.",
      "Test Cup with cover and GO - NOGO gauge.",
      "Motorized/Manual operation.",
      "M.S Powder coated steel cabinet with heavy duty handles.",
    ],

    "ELECTRODES AND PRINCIPLE OF THE BREAKDOWN TEST": `The copper, brass, bronze or stainless steel polished electrodes shall be either
    spherical (12.5mm to 13.0 mm diameter) or spherical surface of the shape.
    The electrodes are mounted on a horizontal axis and 2.5 mm apart.
    The gap between them shall set to an accuracy of +/- 0.01 mm by means of
    thickness gauges.

    The axis of the electrodes is immersed to a depth of approximately 40 mm.
    The test consists in applying to the electrodes an increasing AC voltage. The rate of
    increase of voltage being uniform and equal to approximately 2kV/s, starting from
    Zero up to the value producing breakdown.

    The breakdown voltage is the voltage reached during the test at the time the first
    spark occurs between electrodes, whether it be transient or established`,

    "TECHNICAL SPECIFICATIONS": {
      "Input voltage": "230 Volts +/- 10%, 50Hz AC single phase.",
      "Output voltage": "0 - 60kV, 0 -100kV, 1KVA",
      Display:
        "A Linear Scaled A.C. rectifier voltmeter marked kV to measure output voltage.",
      Indicator: "Mains 'ON', H.T.'ON' & H.T. 'OFF' Indication.",
      "Interlocking & Safety Protection": `Test Cup H.T. Chamber door interlocking & fast acting DC
          relay to isolate the H.T. Supply.
          Test Cup with cover is made of Methyle
          Methacrylate (ACRYLIC) having oil between 300
          and 500ml, with adjustable and removable
          mushroom head and ground to adjust the Electrode
          gap distance`,
    },

    Accessories: [
      "Mains Cord.",
      "Test Vessel with Electrodes",
      "Stainless Steel GO-NOGO gauge",
      "Operating Manual.",
      "Factory Calibration Report.",
      "Warranty Certificate",
    ],
  },
  {
    name: "MICROPROCESSOR AUTOMATIC OIL INSULATION TEST KIT OTK-60A/100A",
    file: "/files/MICROPROCESSOR AUTOMATIC OIL INSULATION TEST KIT.pdf",
    isBestSeller: false,
    images: ["/images/products/17.png", "/images/products/18.png"],
    Intro: `QOITS-A is tested for testing dielectric liquids with their breakdown voltage measuring. it work micro controller based
    automatic operation, according with selected standard, which are preloaded.`,
    Features: [
      "Compact and lightweight",
      "Auto operation mode automatic operation",
      "Fully compatible with IEC 60156",
      "Preloaded testing programs for different standards",
      "Built-in printer",
      "PC connectivity",
      "250 Internal test data storage",
      "Precise voltage measurement",
      "True sine high-voltage on el ectrodes",
      "Indicator HT ON/OFF, All safety Interlock incorporated, Zero Start, HT Chamber interlock",
    ],

    "TECHNICAL SPECIFICATIONS": {
      "Output Voltage": "0-100KV AC",
      Accuracy: "+ 1% of FSD + 2 digit",
      Resolution: "0.1KV",
      Display: "20 x 4 backlit LCD",
      "Tripping Time": "less than 10 micro-seconds",
      "Power Supply": "230VAC, 50Hz, Single Phase",
      Controls: "HT ON, HT OFF, Increase, Decrease,",
      "Oil Cell": "Transparent polycarbonate molded",
      Printer: "Thermal Printer",
      Memory: "250 resul ts",
      Weight: "40Kg approx",
    },

    "SCOPE OF SUPPLY": [
      "Oil BDV",
      "Oil Test Cell with electrodes",
      "Go-No-Go gauge",
      "Mains cord",
      "User / Instruction manual",
    ],
    "TEST VESSEL": [
      "Made of Methyl Mathacrylate (Acrylic)",
      "Volume 300/400ml approx",
      "Mushroom electrodes as per IS:6792, IEC 156 and 3 Custom Modes.Lifting Stick",
    ],
  },

  {
    name: "Primary Current Injection Sets",
    file: "/files/Primary Current Injection Kit.. 2022.pdf",
    isBestSeller: true,
    images: ["/images/products/19.png", "/images/products/29.jpg"],
    Intro: `These are low voltage high current A.C. power supplies, output isolated from Input-Output may be single range or multi range
    involving series - parallel connection of secondary winding of loading transformer.
    Testing specially if the protective gear is connected to the power line via a current it is advisable to carry out primary injection
    testing.

    Neon Controls Primary Current Injection Kits are used to test Circuit Breakers, Bus Bars, CTs, Relays, MCBs, MCCBs etc. These are
    also used for high current injection during commissioning of protection systems and after major repairs and component
    replacement in power substations.
    `,
    Features: [
      "Single compact unit equipment for easy operation.",
      "The kit is designed to offer continuously variable output current.",
      "Built in Digital timer for measuring tripping time.",
      "Digital meter of Accuracy class 0.5% and LED display for displaying input voltage, output current.",
      "The kit is having adequate MCB protection to protect from overloads",
    ],

    "TECHNICAL SPECIFICATIONS": {
      "Input power Supply ": "220 Volts +/- 10%, 50Hz AC single phase.",
      "Output Test Current ": "Refer Table A",
      "Output Test Current Range": "Refer Table A",
      "Burden (Capacity) ": "Refer Table A",
      "Duty Cycle ": "10minutes ON -20minutes OFF",
      "Mode of Operation ": "Manual or Motorized.",
      "Display /Metering ": `A Digital Ammeter of 96* 48 mm size having
accuracy class 0.5 to measure output
current.
Digital Voltmeter 96*48 mm size with
Accuracy class 0.5 to measure input voltage.
Inbuilt digital time interval meter with range
from 0.0001 to 9999 sec. to measure
tripping time.`,
      "Protections and Safety ": `Overload Protection by MCB.
HRC fuse protection for control circuit.
Zero start interlocking.`,
      "Indications ":
        "Bright LED Indicators are provided for Input and Output Indication.",
      "Cooling ": "Air/Oil Cooling.",
      "Control ": "One Knob control to vary Output current.",
    },

    Accessories: [
      `Output cable : One pair of copper flexible
        cable each of 2 Mts long duly fixed with
        Heavy Duty Crocodile Clip and Copper lugs
        or as per customer requirement.`,
      "Operating Manual.",
      "Factory Calibration Report.",
      "Warranty Certificate",
    ],
  },

  {
    name: "RELAY TEST SET - RTS",
    file: "/files/RELAY TEST SET RTS..2022.pdf",
    isBestSeller: false,
    images: ["/images/products/20.png", "/images/products/30.jpg"],
    Intro: `Neon Controls Relay Test Set (type RTS) is a portable
    equipment, which is designed to check the operating characteristics of protective instruments.

    Protective instruments such as rel ays in the system are installed to deal with
    various fault conditions and its failure to operate correctly can cause serious damage
    to substations causing plant shutdown or power outgoes resulting in financial losses.

    To check all types of Electromagnetic relays like Over current, Earth Fault,
    Over voltage, Under Voltage and other relays to ensure smooth
    and fault free operations in plants and power substations.
    `,

    Features: [
      "Single compact unit equipment suitable for field as well as workshop application.",
      "Offer specified output current selectable and continuously variable.",
      "Digital Ammeter & Voltmeter to measure output currents and voltages.",
      "The Test Kit will be in one unit.",
      "Meters, Terminals, indicators, Regulator knob, Timer Display, etc. will be fitted on the horizontal panel of the cabinet.",
      "Input cord of 2 meters length with 3pin plug will be provided.",
      "Loading transformer, Measuring CT & protection circuit will also be housed within the same unit",
    ],

    "TECHNICAL SPECIFICATIONS": {
      "Input power Supply ": "220 Volts +/- 10%, 50Hz AC single phase.",
      "Output Test Current ": "0 - 100 - 200 Amps.",
      "Output Test Current Range":
        "0 - 5 -10 - 25 - 50 - 100 & 200 Amps.  (Selectable & Continuously Variable)",
      "Output AC Voltage ": "Output AC Voltage Continuously Variable 0 - 250",
      "Output D.C. Voltage ": "0 - 270V. Continuously Variable.",
      "Burden ": "Continuous",
    },

    Accessories: [
      "Operating Manual.",
      "Output connecting cables.",
      "Mains cord.",
      "Works Calibration report.",
      "Warranty Certificate",
    ],

    "Metering/ Display": [
      "Digital AC Ammeter with LED display to measure output current (1 Qty).",
      "Digital AC voltmeter with LED display to measure AC output voltage (1 Qty).",
      "Digital DC voltmeter with LED display to measure DC. output voltage (1 Qty).",
      "Digital Time Interval Meter having Range from 0.0001 sec. to 9999% sec to measure tripping time (1 Qty).",
      "AUTO cut off Facility.",
      "The set is provided facilities to cut off the output when the Relay under test is tripped.",
      "Fuse protection provided for overload protections.",
    ],
  },

  {
    name: "3 PHASE AUTOMATIC TRANSFORMER TURNS RATIO METER VTRM - 3A/3B/3C (ANSI/IEEE C57.12.90 COMPLIANCE)",
    file: "/files/3_phase_transformer.pdf",
    isBestSeller: false,
    images: ["/images/products/21.png"],
    Intro: `Neon Controls make Automatic Transformer Turns Ratio Meter - VTRM measures no-load Turns Ratio of Transformer. It means
    Ratio of high Voltage Winding to Low Voltage Winding of Transformer. It is Capable to test Y-y, D-d & Y-d configuration. Turns
    Ratio can be read directly from front LCD display. It also measure and display % Deviation & Excitation Current. NTRM has
    inbuilt Data Storage Memory up to 200 Test Results. It can transfer all test result to Computer using dedicated Software &
    computer interface.
    `,
    Features: [
      "Automatic Measurement of Transformer Turns Ratio",
      "Hold Facility To Freeze Result with LED indication",
      "Shows Excitation Current, HV LV Voltage, Polarity & %Deviation",
      "Test Voltage Applied Through ON/OFF Switch",
      "Vector Group selection facility (VTRM-3C Model)",
      "Reverse Pol arity Indication",
      "Basic Accuracy +0.1%",
      "Over Current Protection for shorten Winding",
      "Turns Ratio 1-200 / 1-2000",
      "Inbuilt 200 Test Data Storage",
      "20 x 4 LCD to Display all Parameters in Single Screen",
      "Rs-232 & USB Interface with Software",
      "3-Phase Connections enables simple and fast testing",
      "Portable in Size (Table Top & Panel Mount)",
      "Hook Up Error Indication for Wrong HV LV Connection",
      "LED Indication for Fuse Blown (YY) (VTRM-3C Model)",
    ],

    "TECHNICAL SPECIFICATIONS": {
      "VTRM-3A Phase": "3 ",
      "VTRM-3B Phase": " 3",
      "VTRM-3C Phase": " 3",
      "VTRM-3A Connection Type": "Y-y D-y ",
      "VTRM-3B Connection Type": "Y-y D-y ",
      "VTRM-3C Connection Type": "Y-y D-y D-d Y-d",
      "VTRM-3A Ratio Range": " 1.0 - 200.0",
      "VTRM-3B Ratio Range ": "1.0 - 2000.0 ",
      "VTRM-3C Ratio Range ": "1.0 - 2000.0",
      "Operating Temperature": " 5°C To 50°C",
      "Operating Humidity": " 20% to 80% RH (no condensation)",
      "Storage Temperature ": "5°C To 55°C",
      "Power Consumption ": "30 VA max",
      Resolution: " 5 Digits",
      Parameters:
        " Turns Ratio, Excitation current, HV & LV Voltage, %Deviation & Reverse Polarity",
      "Test Voltage":
        " 555 Volts @50Hz (Depends on Auxiliary Supply) (Other Range Available on Request)",
      "Excitation Current Range":
        " 200mA, Resolution 0.1mA (Other Range Available on Request)",
      "Power Supply":
        " AC 230V +/- 10% @ 50 Hz or AC 110V +/- 10% @ 60 Hz (O n Request)",
      Accuracy: " +/-0.2% rdg +/-0.1% mg (Foe=r Voltage & Ratio)",
      "Size of Instrument (WxDxH)in mm": "  376mm x 320mm x 145mm(Over all)",
      "Weight of Instrument": " 8.00 kg Approx.",
      Warranty:
        " 1 Year against manufacturing defect & Extended optional. No Warranty for accessories.",
    },

    Note: [
      "Above all specifications are subject to change without prior notice Please confirm at the time of placing an order.",
      "Size and weight may be change without prior notice. For more details please contact Us.",
    ],
    Accessories: [
      "HV & LV Cable.",
      "Power Cable RS-232 & USB Cable.",
      "Accessories Bag.",
      "Software CD.",
      "Document File.",
    ],
    APPLICATIONS: [
      "Distribution Transformers, Power Transformers, CT PT & Other Transformer manufacturers & Repairers etc.",
      "Electricity Distribution & Transmission Companies to enhance their in house testing at micro Level.",
      "Electrical Test & Calibration Laboratories & Third Party Inspection Agencies.",
      "Engineering Colleges to aware students about Turns Ratio & it‘s measuring principles.",
      "Research Institutes for research work on Transformer Fundamentals.",
    ],
  },

  {
    name: "AUTOMATIC POWER FACTOR CONTROL PANEL",
    file: "/files/A P F C R PANEL....2022.pdf",
    isBestSeller: false,
    images: ["/images/products/25.png"],
    Intro: `Neon Controls has full-fledged expertise in solving problems related to electrical energy management. our product are dedicated
    to the betterment of utilization of electrical energy.

    We have vast experience in the installation and commissioning of automatic power factor control system based on your
    controllers and allied products. We offer our experience based on this experience to you by way of providing problems of energy
    management related to power factor improvement
    
        What is Power Factor(P.F.)?
    Power Factor (P.F.)is the ratio of active power to apparent power-i.e P.F.=KW/KVA Power Factor is the - so - to say efficiency of
    utilization of electrical power. It is obvious that KW =KVA X P.F. Thus for a given voltage and active power (KW), the current
    drawn is inversely proportional to the power factor.
`,
    "General Specifications": [
      "Substantial reduction in kVA demand and avoid penal ty for low PF.",
      "Considerable reduction of Transformer and line loses.",
      "Reduction on Voltage drop resulting in better system voltage regulation.",
      "Reduction in maintenance and capital cost because distribution equipment like cable, breaker have to carry less current.",
      "Reduction of voltage fluctuation and circuit reactance.",
      "Reduction in reactive power demand from the supply system since PFC panel itself provides reactive power of inductive loads.",
      "Reduced I²R losses.",
      "Longer Life for all electrical equipments such as motor, Switchgears, Cable, Busbar, Transformer, etc.",
      "Payback for the PF Panel is normally within 12 to 15 month.",
    ],
  },

  {
    name: "DIGITAL Earth Tester",
    file: "/files/DIGITAL CAPACITANCE METER...2022.pdf",
    isBestSeller: false,
    images: ["/images/products/34.jpg", "/images/products/31.jpg"],
    Intro: `Digital Earth Resistance Tester is direct
    replacement of the conventional hand generator type tester. It is
    designed for measurement of the resistance of earthing used in the
    electrical equipment as well as for measurement of ground
    resistivity. It can be used for measurements of the other low regular
    and liquid resistances.

    It can also be used for measurement of voltage AC, voltage DC and
    resistance.

    This instrument finds wide application for testing earthing
    installation in power based industries, telecommunication
    networks and electrical traction systems etc.

    RE-CHARGE ABLE BATTERY AND BATTERY CHARGER IS
    AVAILABLE ONLY IN MODEL ERT·1501R.`,

    "General Specifications": [
      "Earth resistance range : 0-10/100/1000Ω",
      "Display:  Large Backlit LCD with dual display",
      "Multimeter function Range : 200kΩ, 750V/ACV, 1000V/DCV.",
      "Sampling Rate :  2.5 times per second.",
      "Zero Adjustment :  Automatic.",
      "Over Range Indicator :  Number 1 of highest digit is displayed.",
      "Low Battery Indication :  The ' ± ' is displayed when the battery",
      "Data Hold :  Voltage drop below the operating voltage.",
      "Lock Facility :  To freeze the displayed data",
      "Operating Temperature :  For continuous hands free operation",
      "Storage Temperature :  0°C to 40°C (32°F to 104°F); < 80% RH",
      "Power source :  -10°C to 60°C (14°F to 140°F); < 70% RH",
      "Dimensions:  DC9V (6x1.5V Size AA· battery or Equivalent)",
      "Weight:  200(L) x 92(W) x 50(H) mm",
      "Accessories:  Approx 700g include battery",
      "Optional Accessories : 4 sets Test kits, 4pcs iron rods, 6pcs battery, Hard Carrying Case, manual.",
      "Electrical Specifications :  Re-chargeable Battery and battery",
    ],
  },

  {
    name: "DC HIGH VOLTAGE TEST SET",
    file: "/files/DC High Vtg. Test Set..2022.pdf",
    isBestSeller: false,
    images: ["/images/products/32.png", "/images/products/33.png"],
    Intro: `Neon Controls DC High Voltage Test sets are suitable for testing electrical insulation of condensers, plastic line tanks, electrical
    cables, PIV of diodes and many others items and are particularly motors, transformers and particularly applicable fo r testing
    electrical equipment at site where portability is vital.

    Generally available in one unit and sets for higher output voltage are available in two units.

    However, in case if required sets in more than two units can also be manufactured.`,
    Features: [
      "Input: 230V, 50Hz AC (Sets with other voltage and frequency can also be made)",

      "Output: Continuously variable from zero to max. rated output voltage Set with 5kV to 100kV output voltage can be made with current capacities of 100mA.",

      "Max. capacity :Available in different standard current 100mA can be made",
      "Zero interlocking is provided for all units.",
      "The output voltmeter is with memory effect i.e. even after H.T. supply trips off due to the failure of equipment under test,the voltmeter continues to show, the voltage at which the test piece has failed.",
      "The H.T. transformers upto 40 KV model are resin encapsulated, which ensures the protection from atmospheric effects and long life.",
      "AC and DC combined high voltage sets are also available.",
      "Please inform the kV rating, leakage current rating and also inform whether the test set is AC or AC/DC combined, at the time of placing the order.",
    ],
  },
  {
    name: "Product Range Catalog 2023",
    file: "/files/Product_Range_Catalog_2023.pdf",
    isBestSeller: false,
    images: ["/images/products/primg.jpg"],
    Intro: `Neon Controls Product Range Catalog 2023`,
    Features: ["Neon Controls Product Range Catalog 2023"],
  },
];

const files = [
  { name: "NEON ISO CERTIFICATE", file: "/files/ISO_CERTIFICATE_2022.pdf" },
  { name: "NEON CE CERTIFICATE", file: "/files/CE_CERTIFICATE_2022.pdf" },
  { name: "UDYAM CERTIFICATE", file: "/files/UDYAM_CERTIFICATE.pdf" },
  {
    name: "VENDOR REGISTRATION 2022",
    file: "./files/VENDOR_REGISTRATION_2022.pdf",
  },
];

export { products, files };
