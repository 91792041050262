import Grid from "@mui/material/Grid";
import styles from "./Footer.module.scss";
import CopyrightIcon from "@mui/icons-material/Copyright";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Container from "@mui/material/Container";
import { products } from "../../../shared/data/data";
import { NavLink } from "react-router-dom";
import { routes } from "../Navbar/NavbarComponent";
import * as React from "react";

const Footer = (props: any) => {
  function formatName(name: string) {
    return name.replaceAll(/[ /]/g, "-");
  }

  return (
    <>
      <div className={styles.main}>
        <Container maxWidth="xl">
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4} order={{ xs: 2, sm: 1 }}>
              <div className={styles.allProducts}>
                <Typography variant="h5">Our Products</Typography>

                <ul>
                  {products.slice(0, 5).map((prod, index) => {
                    return (
                      <NavLink
                        to={
                          "/product-details/" +
                          (index + 1) +
                          "/" +
                          formatName(prod.name)
                        }
                        key={index}
                      >
                        <li>{prod.name.toLowerCase()}</li>
                      </NavLink>
                    );
                  })}
                  <NavLink to="/products">
                    <li>view all</li>
                  </NavLink>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }}>
              <div className={styles.central_content}>
                <img
                  src="/neonlogo.png"
                  width="200px"
                  height="150px"
                  alt="neonlogo"
                />

                <p>
                  Delighting Customers with Hi-Quality. Customized Products and
                  Services in Energy Management System.
                </p>

                <div className={styles.socials}>
                  <span>
                    <FacebookIcon />
                  </span>
                  <span>
                    <InstagramIcon />
                  </span>
                  <span>
                    <TwitterIcon />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} order={{ xs: 3, sm: 3 }}>
              <div className={styles.quick_links}>
                <Typography variant="h5">Quick Links</Typography>

                <ul>
                  {routes.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <li className={styles.nav_item + " nav-item"}>
                          <NavLink to={item.path}>{item.name}</NavLink>
                        </li>
                        {/* ) } */}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            </Grid>
          </Grid>

          <div className={styles.contact_info}>
            <div className={styles.contact_item}>
              <LocationOnIcon /> A-203, Navkarkripa 2nd Floor, Navroji Ln, near
              Paramkesav Bagh, Mumbai, Maharashtra 400086
            </div>
            <div className={styles.contact_item}>
              <EmailIcon />{" "}
              <a href="mailto:neoncontrolsindia@gmail.com" rel="noreferrer">
                neoncontrolsindia@gmail.com
              </a>
            </div>
            <div className={styles.contact_item}>
              <PhoneIcon />{" "}
              <a href="tel:9869005362" rel="noreferrer">
                9869005362
              </a>
            </div>
            <div className={styles.contact_item}>
              <LinkedInIcon />{" "}
              <a href="" rel="noreferrer">
                LinkedIn
              </a>
            </div>
          </div>

          <hr />

          <div className={styles.credits}>
            <div>
              Designed by{" "}
              <a href="https://kicksmedia.in" target="_blank" rel="noreferrer">
                Kicks Media LLP
              </a>
            </div>

            <div>
              {" "}
              <CopyrightIcon /> {new Date().getFullYear()}. All rights reserved.
              Neon Controls Pvt. Ltd.{" "}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
