import styles from "./UpperNav.module.scss";
import Container from "@mui/material/Container";

const UpperNavComponent = (props: any) => {
  return (
    <>
      <Container maxWidth="xl">
        <div className={styles.main}>
          <img src="/neonlogo.png" alt="neonlogo" height="100" />

          <div>
            <img src="/images/c2.jpg" alt="neoncertificates" height="100" />
            <img src="/images/c1.jpg" alt="neoncertificates" height="100" />
          </div>
        </div>
      </Container>
    </>
  );
};

export default UpperNavComponent;
